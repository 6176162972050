import zhouLin from '@/assets/images/about/周林.jpg'
import zhangWanCheng from '@/assets/images/about/张万成.jpg'
import wuLiHua from '@/assets/images/about/伍丽华.jpg'
import suTing from '@/assets/images/about/苏婷.jpg'
import liuWenjiang from '@/assets/images/about/刘文江.jpg'
import dingQiYong from '@/assets/images/about/丁启永.jpg'
import lixin from '@/assets/images/about/李欣.jpg'
import zhouWei from '@/assets/images/about/周炜.jpg'
import c_1 from '@/assets/images/about/company_1.png'
import c_2 from '@/assets/images/about/company_2.png'
import c_3 from '@/assets/images/about/company_3.png'
import c_4 from '@/assets/images/about/company_4.png'

export const managerList = [
  {
    name: '周林博士 / Lin Zhou',
    job: '董事长兼CEO',
    picture: zhouLin,
    description:
      '晓羊集团董事长兼CEO周林博士是美国特拉华大学计算机专业博士、宾夕法尼亚大学沃顿商学院科技管理硕士，曾任全球著名的教育软件公司Blackboard全球副总裁。2015年，周林博士收购了Blackboard Schoolwires中国区的全部业务和人员，并成立了晓羊。',
  },
  {
    name: '周炜 / Wei Zhou',
    job: 'CTO',
    picture: zhouWei,
    description:
      '毕业于美国特拉华大学（University of Delaware），获计算机博士学位。拥有超过18年的超大平台研发、集成与运营管理经验，曾任美国摩根大通资深副总裁，年营收入80亿美元的客户忠诚平台负责人，总设计师。',
  },
  {
    name: '苏婷 / Ting Su',
    job: 'CFO',
    picture: suTing,
    description:
      '毕业于法国图卢兹经济学院（Toulouse School of Economics），获金融市场和中介硕士学位，中国注册会计师(CICPA)，特许金融分析师(CFA)。拥有超过10年的高科技领域投资上市发行以及审计经验，曾任职于德勤及中金核心岗位。',
  },
  {
    name: '伍丽华 / Lihua Wu',
    job: '副总裁 ',
    picture: wuLiHua,
    description:
      '超过20年国内国际企业工作经历，其中包括超过 10 年高端人才寻访经验以及超过7年的人力资源管理经验，先后任职于世界五百强中国分公司，新能源高新科技企业。',
  },
  {
    name: '李欣 / Xin Li',
    job: '副总裁 ',
    picture: lixin,
    description:
      '20年IT及教育信息化行业从业经验，清华大学社会治理与发展研究院教育治理与发展课题专家，北京市产品创新杰出管理者，原北京合众天恒科技有限公司创始人及总裁，原立思辰教育集团副总裁，原青蚕教育创始人，深耕智慧教育生态建设。',
  },
  {
    name: '张万成 / Wancheng Zhang',
    job: '副总裁',
    picture: zhangWanCheng,
    description:
      '前Blackboard K12中国区研发总监，拥有超过15年丰富的技术及企业级软件开发经验。在国内K12技术领域深耕多年，对K12学校核心业务的技术实现有着深厚的积累。',
  },
  {
    name: '刘文江 / Wenjiang Liu',
    job: '高级副总裁|教育研究院院长',
    picture: liuWenjiang,
    description:
      '中国管理科学研究院基础教育研究所研究员，中国教育发展战略学会教育教学创新专业委员会常务理事，广东省教育学会教育创新教育专委会常务理事。多年从事基础教育理论研究与实践探索，指导全国30余所学校的课程体系建设和课堂教学改革。',
  },
  {
    name: '丁启永 / Qiyong Ding',
    job: '高级副总裁 ',
    picture: dingQiYong,
    description:
      '超过20年中学教育从业者，其中包含13年教育信息化管理工作经验；常年深耕教育信息化产品和市场，主导规划和设计了全国多所新建校信息化应用生态，对教育信息化与教育教学融合有深度思考和独到见解。',
  },
]
export const managerList_m = [
  {
    name: '周林博士 / Lin Zhou',
    job: '董事长兼CEO ',
    picture: zhouLin,
    description:
      '晓羊集团董事长兼CEO周林博士是美国特拉华大学计算机专业博士、宾夕法尼亚大学沃顿商学院科技管理硕士，曾任全球著名的教育软件公司Blackboard全球副总裁。2015年，周林博士收购了Blackboard Schoolwires中国区的全部业务和人员，并成立了晓羊。',
  },
  {
    name: '周炜 / Wei Zhou',
    job: 'CTO',
    picture: zhouWei,
    description:
      '毕业于美国特拉华大学（University of Delaware），获计算机博士学位。拥有超过18年的超大平台研发、集成与运营管理经验，曾任美国摩根大通资深副总裁，年营收入80亿美元的客户忠诚平台负责人，总设计师。',
  },
  {
    name: '苏婷 / Ting Su',
    job: 'CFO',
    picture: suTing,
    description:
      '毕业于法国图卢兹经济学院（Toulouse School of Economics），荣获金融市场和中介硕士学位，中国注册会计师(CICPA)，特许金融分析师(CFA)。拥有超过十年的高科技领域投资上市发行以及审计经验，曾任职于德勤及中金核心岗位。',
  },
  {
    name: '伍丽华 / Lihua Wu',
    job: '副总裁 ',
    picture: wuLiHua,
    description:
      '超过20年国内国际企业工作经历，其中包括超过 10 年高端人才寻访经验以及超过7年的人力资源管理经验，先后任职于世界五百强中国分公司，新能源高新科技企业。',
  },
  {
    name: '李欣 / Xin Li',
    job: '副总裁 ',
    picture: lixin,
    description:
      '20年IT及教育信息化行业从业经验，清华大学社会治理与发展研究院教育治理与发展课题专家，北京市产品创新杰出管理者，原北京合众天恒科技有限公司创始人及总裁，原立思辰教育集团副总裁，原青蚕教育创始人，深耕智慧教育生态建设。',
  },
  {
    name: '张万成 / Wancheng Zhang',
    job: '副总裁',
    picture: zhangWanCheng,
    description:
      '前Blackboard K12中国区研发总监，拥有超过15年丰富的技术及企业级软件开发经验。在国内K12技术领域深耕多年，对K12学校核心业务的技术实现有着深厚的积累。',
  },
  {
    name: '刘文江 / Wenjiang Liu',
    job: '高级副总裁|教育研究院院长',
    picture: liuWenjiang,
    description:
      '中国管理科学研究院基础教育研究所研究员，中国教育发展战略学会教育教学创新专业委员会常务理事，广东省教育学会教育创新教育专委会常务理事。多年从事基础教育理论研究与实践探索，指导全国30余所学校的课程体系建设和课堂教学改革。',
  },
  {
    name: '丁启永 / Qiyong Ding',
    job: '高级副总裁 ',
    picture: dingQiYong,
    description:
      '超过20年中学教育从业者，其中包含13年教育信息化管理工作经验；常年深耕教育信息化产品和市场，主导规划和设计了全国多所新建校信息化应用生态，对教育信息化与教育教学融合有深度思考和独到见解。',
  },
]
export const awardsList = [
  {
    year: '2016',
    details: {
      certificates: '',
      competitionList: [
        '"一人一课表”平台获得国家版权局计算机软件著作权登记书',
        '荣获2016创新中国总决赛暨秋季峰会消费升级专场“优秀企业”',
        '获得中关村高新技术企业资质',
        '获得2016年度中关村海外人才创业支持资金支持',
        '入选中关村国家自主创新示范区“金种子工程”',
      ],
    },
  },
  {
    year: '2017',
    details: {
      certificates: '',
      competitionList: [
        '获得2017年互联网教育企业家年会"2016最具影响力奖"',
        '获得2017年北京市最具投资价值企业50强奖项',
        '"一人一课表"平台获得北京市新技术新产品（服务）证书',
      ],
    },
  },
  {
    year: '2018',
    details: {
      certificates: '获得国家高新证书、双软企业称号',
      competitionList: [
        '获得2018互联网教育家企业年会“最佳教育解决方案奖”',
        '获得2018年中国教育技术协会“数字校园好方案优秀奖”',
        '获评“2018最具投资价值企业”50强，荣膺新芽榜第五名',
        '获评“2018年度中国创客”50强',
      ],
    },
  },
  {
    year: '2019',
    details: {
      certificates: '获得AAA证书、ISO9001质量认证、CMMI3认证',
      competitionList: [
        '晓羊董事长&CEO周林博士荣膺“2018最具成长力教育企业家”',
        '获得“第76届中国教育装备展示会金奖”',
        '获评DEMO CHINA“2019年中国教育创新企业30强”',
        '获得商业评论“2019年商业模式奖”',
        '荣获2019年中国教育“金知奖”',
      ],
    },
  },
  {
    year: '2020',
    details: {
      certificates:
        '获得ISO20000信息技术服务管理体系证书、ISO27001信息安全管理体系证书、信息系统安全等级保护3级证书',
      competitionList: [
        '入选“2020年微软加速器企业”榜单',
        '获得HICOOL2020全球创业大赛二等奖',
        '获得“2020北京软件企业核心竞争力评价（成长型）”称号',
        '入选雷锋网2020年最佳AI新基建年度榜，获得最佳智慧校园落地奖',
        '荣获2020年度最具投资价值奖',
        '荣登2020年中国企服行业最具成长力TOP50榜单',
      ],
    },
  },
  {
    year: '2021',
    details: {
      certificates:
        '荣获ITSS信息技术服务标准符合性证书，及中国网络社会组织联合会会员单位、北京软件和信息服务业协会第十届理事会会员单位',
      competitionList: ['获评知顿“新商业潜力榜教育领域TOP10”'],
    },
  },
  {
    year: '2022',
    details: {
      certificates:
        '荣获中国教育装备行业企业信用AAA等级评价认证、ISO9001质量管理体系认证',
      competitionList: [
        '获选北京市2022年度首批专精特新企业',
        '荣获中国品牌圈“小奥斯卡”斯贝瑞奖——2022年度数字化校园建设领军品牌',
        '荣获全球教育智库Holon IQ 2022东亚 EDTECH 150强',
      ],
    },
  },
  {
    year: '2023',
    details: {
      certificates: '成功入选“中国教育装备行业品牌企业案例公示名单”',
      competitionList: [
        '荣获2023年度科创引领企业，“四维一体”区域智慧教育平台获评2023年度数字化先锋产品奖',
      ],
    },
  },
  // 获得AAA级信用企业评价认证、ISO9001质量管理体系认证、获选北京
]

export const culture = [
  {
    pic: c_1,
    title: '企业愿景',
    title_en: 'Corporate Vision',
    content: '让教育更加高效而美好',
    content_m: '让教育更加高效而美好',
  },
  {
    pic: c_2,
    title: '企业使命',
    title_en: 'Corporate Mission',
    content:
      '提供基于数据发现、分析、整合与思考的个性化教育解决方案和温暖的智能服务，与客户共同创造持久的价值',
    content_m:
      '提供基于数据发现、分析、整合与思考的个性化教育解决方案和温暖的智能服务，与客户共同创造持久的价值',
  },
  {
    pic: c_3,
    title: '企业宗旨',
    title_en: 'Corporate Purposes',
    content: '通达至简，智晓未来',
    content_m: '通达至简<br>智晓未来',
  },
  {
    pic: c_4,
    title: '企业精神',
    title_en: 'Enterprise Spirit',
    content: '群勇诚智',
    content_m: '群勇诚智',
  },
]
