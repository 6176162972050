<template>
  <div id="about">
    <div class="top">
      <img src="@/assets/images/about/banner.png" alt="" />
      <ul class="about_us">
        <div>
          <li>
            <h1>关于我们</h1>
          </li>
          <!-- <li class="en">ABOUT US</li> -->
          <li>致力于赋能区域教育优质均衡高质量发展</li>
        </div>
        <!-- <li class="en">
          Innovation and development of <br />
          self-improvement pursuit of excellence
        </li> -->
      </ul>
    </div>
    <div class="content">
      <!--  ceo致辞  -->
      <div class="ceo">
        <div class="title">
          <h3>CEO致辞</h3>
          <p class="title_en">
            <!-- Make A Speech -->
          </p>
        </div>
        <div class="ceo_content">
          <img src="@/assets/images/about/ceo_left.png" alt="ceo致辞图片" />
          <div>
            <div class="h4">董事长兼CEO 周林博士</div>
            <p>
              一家肩负伟大使命的企业，需要经历巨大的挑战，需要艰苦卓绝的努力。晓羊是一个肩负教育使命的企业，希望能在迅速变化的时代，让每一个孩子在每一个角落不被落下，让每个人找到自己个性化的发展路径和独特的幸福感。坚守这样的使命，我们已经在创业原始森林的探索中找到了正确的方向和路，看到了前方的光；我也似乎正看见远方有荣耀的冠冕存留，一群晓羊人不忘初心、砥砺前行，在坚韧与盼望中，满心欢喜，得胜有余。
            </p>
            <!-- <img src="@/assets/images/about/ceo_bottom.png" alt="周林总" /> -->
          </div>
        </div>
      </div>
      <!--  晓羊集团简介  -->
      <div class="xy_introduction">
        <div class="title">
          <h3>晓羊集团简介</h3>
          <p class="title_en">
            <!-- Xiaoyang Group Profile -->
          </p>
        </div>
        <div class="xy_introduction_content">
          <div class="paragraph">
            <div class="pc">
              <p>
                北京晓羊科技集团有限公司是专注的智慧教育全栈产品与服务提供商。集团以“科技助力教育优质均衡、更高质量发展”为使命，秉承“应用驱动、融合创新、统筹规划、品质服务”的理念，把握当前教育数字化转型路径，充分运用大数据、云计算和人工智能等技术手段，以数据融通的全栈产品生态为核心，积极建设互联互通、应用齐备、协同服务的“互联网+教育”大平台，构建覆盖全场景、全方案、全服务的智慧教育生态，创造性地帮助教育行政机关、学校以及教师全面提升管理和专业发展水平，以智慧教育生态全面赋能新时代教育更高质量发展，推动实现国家五级平台的融合发展，加速推进基础教育优质均衡发展及城乡一体化。
              </p>
              <p>
                以区域统筹为主导+学校自主选择相结合的业务模式，晓羊集团探索出“区-校”数据融合平台的实施路径，打造“四维一体”高质量发展的区域智慧教育解决方案，构建连接区域智慧教育基础平台、区域教育综合管理平台、区域学生发展综合服务平台的区域“互联网+教育”大平台，以助学、助教、助管、助研、助政为己任，打造面向政府、学校、社会、家庭的教育全业务生态，从数据、平台、应用三大维度全面赋能区域教育数字化转型、升级与发展。
              </p>
            </div>
            <p class="mobile">
              北京晓羊科技集团有限公司成立于2016年5月，是专注的智慧教育全栈产品与服务提供商，拥有国际水准的技术研发团队，覆盖31个省份的销售网络，集团管理团队由教育领域的专家、教育信息化规划专家及现代企业管理精英组成。
            </p>
            <p class="mobile">
              公司秉承“应用驱动、融合创新、统筹规划、品质服务”的理念，积极面对教育信息化、人工智能的迅猛发展以及新高考引发的课程改革浪潮。依据对教育教学及课程改革的深刻理解，晓羊集团通过打造拥有自主知识产权的、数据融通的、全栈的产品生态，运用大数据技术和云计算为教育行业提供基于数据发现、分析、整合与思考的高效智能产品与服务，创造性地帮助教育行政机关、学校以及教师全面提升管理和专业发展水平，以智慧教育生态全面赋能教育高质量发展。
            </p>
            <p class="mobile">
              集团获选北京市2022年度首批专精特新企业，连续三年被评为国家高新科技企业和最有价值的投资企业。
            </p>
            <!-- <p>
              晓羊集团销售渠道网络已覆盖全国
              <span
                >31个省市区，服务超9000+学校，2千万师生，成功编排2亿张课表。</span
              >
            </p>
            <p>
              集团管理团队由教育领域的专家、教育信息化规划专家及现代企业管理精英组成，
              <span
                >现有员工超500人，下属14个子公司，8个研发中心，已完成6轮融资。</span
              >
              晓羊集团连续三年评为国家高新科技企业，连续三年被评为最有价值的投资企业。
            </p> -->
            <ul v-if="false">
              <li>
                <img src="@/assets/images/about/icon.png" alt="ceo致辞图片" />
                <span class="zd"
                  ><span>连续</span><b>6</b
                  ><span>年获千万级或亿级融资</span></span
                >
              </li>
              <li>
                <img src="@/assets/images/about/icon.png" />

                <span class="zd"
                  ><span>连续</span><b>3</b
                  ><span>年评为国家高新科技企业</span></span
                >
              </li>
              <li>
                <img src="@/assets/images/about/icon.png" />
                <span class="zd"
                  ><span>连续</span><b>3</b
                  ><span>年被评为最有价值的投资企业</span></span
                >
              </li>
              <li>
                <img src="@/assets/images/about/icon.png" />
                <div>
                  <span class="zd"
                    ><span>旗下</span><b>18</b><span>家子公司</span></span
                  >
                  <span class="zd"><span>覆盖</span><b>31</b></span
                  >个省份
                </div>
              </li>
              <li>
                <img src="@/assets/images/about/icon.png" />
                <div>
                  <span class="zd"
                    ><span>拥有</span><b>8</b><span>个研发中心</span></span
                  >
                  <span class="zd"><span>获得</span><b>22</b></span
                  >项专利
                </div>
              </li>
              <li>
                <img src="@/assets/images/about/icon.png" />
                <div>
                  <span class="zd"
                    ><span>服务超</span><b>10000+</b><span>所学校</span></span
                  >
                  <br />
                  <span class="zd"><b>2000</b></span
                  >万+师生
                  <span class="zd"
                    ><span>成功编排</span><b>2</b><span>亿张课表</span></span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  大事记  -->
      <div class="milestones">
        <div class="title">
          <h3>大事记</h3>
          <p class="title_en">
            <!-- Milestones -->
          </p>
        </div>
        <div class="milestones_content">
          <img
            :src="
              $store.state.deviceWidth > 768 ? milestonesPc : milestonesMobile
            "
            alt=""
          />
        </div>
      </div>
      <!--  管理人员  -->
      <div class="manager" @mouseleave="toFirst">
        <div class="title">
          <h3>管理人员</h3>
          <p class="title_en">
            <!-- Manager -->
          </p>
        </div>
        <div class="manager_content">
          <template v-if="$store.state.deviceWidth > 769">
            <el-carousel
              :interval="4000"
              :autoplay="false"
              arrow="always"
              indicator-position="none"
              type="card"
              :height="height + 'px'"
              @change="handleCarouselChange"
              ref="carousel"
            >
              <el-carousel-item
                class="haha"
                v-for="(item, index) in managerList"
                :key="index"
              >
                <div class="manage_item">
                  <img
                    @load="getImageDomHeight"
                    :src="item.picture"
                    :alt="item.name"
                  />
                  <div v-show="carouselActiveIndex === index">
                    <p>{{ item.name }}</p>
                    <span>{{ item.job }}</span>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="manage_desciption">
              {{ managerList[carouselActiveIndex].description }}
            </div>
          </template>
          <template v-else>
            <div class="manager_list_mobile">
              <swiper ref="swiper" class="swiper" :options="swiperOption">
                <!--        <swiper-slide>-->
                <!--          <img-->
                <!--            src="@/assets/images/schoolProduct/banner_products_school.png"-->
                <!--            alt="校端产品 一人一课表"-->
                <!--          />-->
                <!--          <div class="top_title">-->
                <!--            <h3>校端产品 「 晓羊一人一课表 」</h3>-->
                <!--            <p>-->
                <!--              基于启发式搜索、基因算法、隐马尔科夫模型等核心算法，可支持任意形式的排课需求-->
                <!--            </p>-->
                <!--          </div>-->
                <!--        </swiper-slide>-->
                <swiper-slide v-for="item in managerList_m" :key="item.name">
                  <div>
                    <img :src="item.picture" alt="" />
                    <div class="name">
                      <p>{{ item.name }}</p>
                      <span>{{ item.job }}</span>
                    </div>
                  </div>
                  <div class="description">
                    {{ item.description }}
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
            <!-- <ul class="manager_list_mobile">
              <li v-for="item in managerList_m" :key="item.name">
                <div>
                  <img :src="item.picture" alt="" />
                  <div class="name">
                    <p>{{ item.name }}</p>
                    <span>{{ item.job }}</span>
                  </div>
                </div>
                <div class="description">
                  {{ item.description }}
                </div>
              </li>
            </ul> -->
          </template>
        </div>
      </div>
      <!--  荣誉资质  -->
      <div class="awards">
        <div class="title">
          <h3>荣誉资质</h3>
          <p class="title_en">
            <!-- Awards & Qualifications -->
          </p>
        </div>
        <ul class="awards_content">
          <li v-for="(item, index) in awardsList" :key="index">
            <div>{{ item.year }}</div>
            <div class="awards_detail">
              <p
                v-show="item.details.certificates.length > 0"
                class="certificate"
              >
                {{ item.details.certificates }}
              </p>
              <ul>
                <li v-for="_item in item.details.competitionList" :key="_item">
                  {{ _item }}
                </li>
              </ul>
            </div>
          </li>
          <div class="awards_content_photos">
            <img src="@/assets/images/about/award_1_n.png" alt="" />
            <img src="@/assets/images/about/award_2_n.png" alt="" />
          </div>
        </ul>
      </div>
      <!--  企业文化  -->
      <div class="company">
        <div class="title">
          <h3>企业文化</h3>
          <p class="title_en">
            <!-- Company Culture -->
          </p>
        </div>
        <ul class="company_content">
          <li v-for="(item, index) in culture" :key="index">
            <img :src="item.pic" alt="" />
            <div class="company_box">
              <div>
                <div class="title">
                  <span>{{ item.title }}</span>
                  <!-- <p>{{ item.title_en }}</p> -->
                </div>
                <div class="content">
                  <p class="pc">{{ item.content }}</p>
                  <p class="mobile" v-html="item.content_m"></p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import milestonesPc from '@/assets/images/about/milestones_pc_n.jpg'
import milestonesMobile from '@/assets/images/about/milestones_pc_n.jpg'
import { managerList, managerList_m, awardsList, culture } from '@/data/about'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import carousel from './Carousel'
import carouselItem from './CarouselItem'

export default {
  name: 'About',
  metaInfo: {
    title: '关于晓羊',
    meta: [
      { name: 'keywords', content: '关于我们' },
      { name: 'description', content: '关于我们的描述性语句' },
    ],
  },
  components: {
    elCarousel: carousel,
    elCarouselItem: carouselItem,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      window,
      milestonesPc,
      milestonesMobile,
      managerList, // 荣誉资质的数据
      managerList_m,
      awardsList,
      culture,
      height: 300,
      carouselActiveIndex: 0, // 当前轮播图激活的index
      swiperOption: {
        autoplay: {
          disableOnInteraction: false, // 用户操作后自动轮播时间重新开始计时
          delay: 5000, // 自动轮播切换时间
        },
        loop: true, // 开启循环
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // dynamicBullets: true,
        },
      },
    }
  },
  methods: {
    // 切换轮播图
    handleCarouselChange(index) {
      this.carouselActiveIndex = index
    },
    // 初始化的时候获取轮播图片dom的高度 ，动态设置轮播图的高度
    getImageDomHeight() {
      this.height = Math.round(
        this.window.document
          .querySelector('.el-carousel__item.is-active')
          .getBoundingClientRect().height + 20
      )
    },
    toFirst() {
      // console.log(1)
      this.carouselActiveIndex = 0
      this.$refs.carousel.activeIndex = 0
    },
  },
  mounted() {
    // 荣誉资质的数据
    this.window.addEventListener('resize', this.getImageDomHeight)
    // 设置header背景透明
    this.$store.commit('setPcHeaderIsTransparent', true)
  },
  beforeDestroy() {
    this.window.removeEventListener('resize', this.getImageDomHeight)
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/pages/about.scss';
</style>
